<template>
    <section>
        <Breadcrumbs/>

        <h1>Favourite Beats</h1>
        <div class="beats">
            <ul v-if="favourites.length">
                <Beat v-for="beat in favourites" :key="beat.name" :beat="beat" ref="beat"/>
            </ul>
            <div class="beats__empty" v-else>
               <p> No beats found</p>
               <p>Click <icon-base width="24" height="16" iconColor="rgb(160, 160, 160)" viewBox="0 -50 500 500" ><icon-hearth/></icon-base> to add beat to favourites</p>
            </div>
        </div>
        
    </section>
</template>
<script>
import { mapState } from 'vuex';
import Beat from '@/components/Beat';
import IconBase from '@/assets/icons/IconBase'
import IconHearth from '@/assets/icons/IconHearth'


export default {
    name: 'Favourites',
     components: {
        Beat,
        IconBase,
        IconHearth
    },
    computed: {
        ...mapState({
            favourites: (state) => {
                const allBeats = state.beats.all;
                const favouritesIDs = state.favourites.favourites;

                const favourites = allBeats ? allBeats.filter(beat => {
                    return favouritesIDs.includes(beat.id)
                }) : []

                return favourites;    
            },
        }),
    }
}
</script>
<style lang="scss" scoped>
    section {
        @include sectionStarter;
        margin-top: 8rem;
        padding-top: 4rem;
        min-height: calc(100vh - 130px);

        & h1 {
            @include fontPoppins;
            color: $lightText;
            font-size: 3rem;
            letter-spacing: .1em;
            z-index: 1;
            margin-top: 6rem;
        }
    }

    .beats {
        @include sectionStarter;
        width: 100%;
        margin-top: 3rem;

        @include mq($to: mobile) {
            width: 100%;
        }

        & ul {
            width: 100%;
            @include fontPoppins;
            font-weight: 300;
            color: $middleText;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;

            & li {
                margin-right: 0;
            }
        }

        &__empty {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            @include fontPoppins;
            color: $middleText;
            z-index: 1;

            p:last-of-type {
                color: $darkText;
            }
        }
    } 
</style>